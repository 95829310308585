/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './TopBar.css';
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { RootReducer } from '../../store';
import { logOut } from '../../firebase/firebase';
import { AppState } from '../../reducers/app';

ReactGA.initialize('G-8DD6R2BMG4');

export default function TopBar() {
  const { user, isAdmin } = useSelector<RootReducer, AppState>((state) => state.app);

  const location = useLocation();
  React.useEffect(() => {
    fbq('track', 'ViewContent');
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <div className="main-container-topbar">
      <div className="sub-container-topbar">
        <div className="container-left-topbar">
          <div className="social-media-links">
            <a href="https://www.facebook.com/iffcoosteopathie/" rel="noreferrer" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </div>
          <div className="social-media-links linkedin-link-topbar">
            <a href="https://www.linkedin.com/in/iffcoosteo/?originalSubdomain=fr" rel="noreferrer" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="social-media-links faq-link-topbar">
            <Link to="/faq">
              <div>faq</div>
            </Link>
          </div>
        </div>
        {user ? (
          <div className="container-right-topbar">
            {isAdmin ? (
              <Link to="/admin">
                <div className="admin-button-topbar">Admin</div>
              </Link>
            ) : (
              ''
            )}
            <Link to="/myspace">
              <div className="login-link-topbar">mon compte</div>
            </Link>
            <div onClick={logOut} role="button" className="login-link-topbar register-link-topbar">
              se déconnecter
            </div>
          </div>
        ) : (
          <div className="container-right-topbar">
            <Link to="/myspace">
              <div className="login-link-topbar">s&apos;identifier</div>
            </Link>
            <Link to="/myspace">
              <div className="login-link-topbar register-link-topbar">s&apos;inscrire</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
